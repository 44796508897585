import React from "react";
import Signature from "../../Signature/Signature";
import { toTemplate } from "../../../core/Utils";
import { Col, Form, Row } from "react-bootstrap";
import Datetime from "react-datetime";

export const label = "06";

export function Cast_06(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="h2_title">入店誓約書</h2>
      </Form.Row>
      <Form.Row>
        <Col sm={1}>
          <Form.Label id="subtitle1">私、</Form.Label>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Control id="subjectA" required defaultValue={props["subjectA#subjectA"]} type="text" name="subjectA" />
          </Form.Group>
        </Col>
        <Col sm={1}>
          <Form.Label id="subtitle2">は、</Form.Label>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Control id="subjectB" required defaultValue={props["subjectB#subjectB"]} type="text" name="subjectB" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p15">にホステスとして勤務するにあたり、下記の条項を確実に厳守履行することを誓約致します。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p93" style={{ float: "right" }}>
            記
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p2">1. 礼儀、マナーを大切にし、ホステスとしての品格向上に努めること。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p3">2. お店の業績向上、店舗繁栄に向けて精力的に業務を遂行すること。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p32">3. 勤務についての詳細は社内規定、及び指示系統を厳守すること。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p33">
            4. 業務上知り得た、会社の内部事情、会社が取引する企業等の内部事情に対し守秘義務を負う。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p34">5. 無断退店の禁止。退店するときは希望日の二ヶ月前までに届け出、承諾を得ること。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p4">6. 違法薬物使用、飲酒運転等、いかなる違法行為の禁止。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p5">7. 会社、及び会社関係者との私的交友、恋愛関係となることの禁止。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p6">8. 在籍中、他店舗での勤務の禁止。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p7">9. 金銭の貸し借りの禁止。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p72">10. 給料の条件の公言を禁止。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p73">11. 身分を詐称する行為の禁止。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p8">12. 店舗規則を遵守すること。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p9" style={{ float: "right" }}>
            以上
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p92">
            上記の条項に違反する行為があった場合、会社の業績に重大な損害を与えることは明白であり、&nbsp;
            万が一私が故意または重大な過失において、上記の各条項に違反し、会社、及び会社が取引する企業等に損害を与えた場合は、&nbsp;
            明確な証拠の有無にかかわらず賠償責任を負います。&nbsp;
            故意にお店の商品、造作物を破損させた場合、仕入れ代、修理費を給料から引きます。&nbsp;
            尚、その場合に店より即刻解雇されても、一切異議申し立て致しません。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <h2 id="h2_title2" p={2}>
          入店誓約書
        </h2>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p94">１、 勤務時間</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p96">
            ・閉店時の上り時間は会計終了時までの時間になります。&nbsp;
            ラストオーダーは遅くても閉店15分前、カラオケのラストソングは閉店10分前を原則とします。&nbsp;
            指名被りで閉店時間をオーバーして会計が終了した場合のみ時給が発生します。 &nbsp;
            ※時給がつくのは遅くても1:15迄となります。&nbsp;
            閉店時間は1:00になりますが、24:50からアップ作業を開始します。1:00にはオールアップできるようキャストも協力して下さい。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p99">
            ・出勤時間の優先順位は成績により決定されます。 同伴、早い時間の来店予定などは最優先されます。&nbsp;
            早上がりの人選については、当日の成績に左右されます。 （※入店してから1週間は上記のルールに該当しません。）&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p100">２、身だしなみ</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p101">
            ・スッピン、ジャージ、クロックスなどで歩かないようにしましょう。 同様に歩きタバコもやめてください。&nbsp;
            ホステスとしてのプライドを持ちましょう。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p102">３、 給料渡し </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p103">
            ・出勤キャストのみ各担当者により手渡されます。月の売上成績が高いキャストから渡されます。&nbsp;
            <br />
            ・給料受け取りの日、クール締め日は日払いができません。 ※給料日が土曜、日曜、祝日の場合、翌平日に振替になります。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p104">４、閉店後アフターがない場合速やかに帰宅すること。店内に長い時間滞在しないこと。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p105">
            まだお客様が店内にいる場合大声でしゃべったり、お客様が見えるところでの写真撮影はNGとする。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p106">
            ５、営業中に待機場所で大声で笑ったり、グループになってしゃべったり、足組みは禁止です。発見した際、スタッフが注意に入ります。&nbsp;
            その後発覚した場合、その場で上がりとします。勤務時間中の待機時間でのゲーム、ショッピングや営業に関係のないことはNGです。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p107">
            6、待機中お客様が帰られる際、必ず立ち上がって私語を止め、お見送りすること。その際携帯電話は隠してください。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p108">
            7、ロッカールーム及びキッチンでの待機は禁止とします。5分以上経過した場合、リストが自動的に時給をカットします。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p109">8、ヘルプに付いた際、必ずテーブルマナーを守ること。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p110">
            ・シャンパンが出ている場合、幸先してお客様に注ぐ &nbsp;
            <br />
            ・ボトルがある場合はお客様のお酒をつくる &nbsp;
            <br />
            ・自分が飲んだグラスは必ず下げる &nbsp;
            <br />
            ・テーブルの上は常にきれいに保つ &nbsp;
            <br />
            ・ドリンクはお客様に勧められた時のみ注文し、自主的に頼まない &nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p111">9、出勤したらスタッフ、キャストに挨拶をすること。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p112">10、スタッフを呼ぶとき役職名で呼ぶなど、お客様の前だということを忘れないこと。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p113">
            11、出退勤時は必ずタイムカードを打刻してください。打ち忘れた場合は時給が付きませんが、自己責任になります。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p114">
            12、営業中の来客予定等の情報はすべて店長もしくはリストに伝えること。
            &nbsp;予約が取れない場合や来客の把握漏れにつながる場合があります。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p115">
            13、席で大声をあげたり、周りに迷惑がかかるように騒いだり、お客様の足の上に乗ったり、&nbsp;
            極端にお触りさせるなど、お店の風紀にそぐわないと判断した場合、その場でスタッフが注意に入ります。&nbsp;
            自分だけが良ければ好し、ではなく周りの人のことも考えて行動し、マナーを守ること。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p116">
            14、会計が終わったら速やかにお客様を帰らせる努力をキャストさん方も協力してください。&nbsp;
            自分のお客様が待ちで帰ってしまう可能性があることを理解してください。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p117">
            15、タイムカードはロッカールームにあります。&nbsp;
            タイムカードを店内まで持ってきて、接客できる準備が完了したらコンシェルジュの時間計で打刻します。&nbsp;
            時給はその時間から発生します。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p118">16、同伴で更衣室に着替えに行く時間は20分以内とする。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p119">
            17、個室予約について、予約時間より30分すぎた場合自動キャンセルとなります。&nbsp;
            また時間が未確定の予約を受けられません。&nbsp;
            予約時間を10分過ぎた場合キャストさんに連絡をとって頂き、確認してもらいます。 30分以内に来店して頂いて下さい。&nbsp;
            尚、予約時間からの時間スタートとなります。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p120">
            18、前期クール（1～15日）のシフト提出期限は前月の25日まで、&nbsp;
            後期クール（16〜31日）のシフト提出期限は当月の10日までになります。 必ず期限を守るようにしてください。&nbsp;
            尚、月単位でシフトを提出できる方はそちらでお願い致します。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p121">19、当日欠勤時の減給について</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p122">
            ・5,000円。&nbsp;
            <br />
            ・病欠の場合は診断書が必要です。（領収証は✕）ない場合は当扱いになります。&nbsp;
            <br />
            ・出勤の振替はクール内で2回までとします。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p123">20、遅時の減給について &nbsp; ・遅刻15分ごとに1,000円です。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p124">21、ノーセットの出勤は基本NGとします。</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p125">22、出勤時はストッキングを必ず履いて下さい。</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p126">
            23、営業中は店内禁煙です。営業中指名（本指名又は場内）のお客様の席でのみ喫煙が可能です。
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p127">
            24、日払いは、本指名呼びがある場合は1日10000円迄、 &nbsp;それ以外の場合は1日5000円迄とする。
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p128">
            25、社用車の免責範囲は20万円とする。 &nbsp;細かい傷など自走可能なものは一律3万円の負担とする。
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p129">26、退店する際は原則2ヶ月前に申し出をして下さい。</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p130">
            27、周年、会長、代表者誕生日について、&nbsp;
            同業者や直接ビジネス関係者などからのお祝いシャンパンは本指名であっても売り上げに付きません。&nbsp;
            一般のお客様のみ売り上げに加算されます。 セットや指名料は売り上げに加算されます。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p131">28、同伴は遅くても必ず 22:00までにお店に入ること。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p132">
            29、SNSに写真や動画などを投稿する場合、ネットリテラシーを守るようにしてください。&nbsp;
            （載せる時は、お客様やほかのキャストのプライバシーは必ず守ってください。&nbsp;
            自分以外が映る場合、キャストやお客様にも許可を頂くようにお願いします。&nbsp;
            また映っていなくても声が入っている場合もありますので、細心の注意を払うようにしてください。）&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p133">30、お店及びグループ方針・マニュアル・規定等に従い、誠実に努める。</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p134">
            31、営業上知り得る情報、個人情報、売上、その他グループに関する一切の機密の持ち出し及び漏洩、&nbsp;業務外使用を契約中は勿論、退店後もしない。
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p135">32、社の内外を問わず、グループの体面を汚すような行為をしない。</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p136">
            ● 給料引き &nbsp;
            <br />
            ・クリーニング1着1,000円、ストッキング500円になります。 &nbsp;
            <br />
            ・キャストからお客様へのシャンパン、ケーキ、フルーツ等、すべて原価引きです。 &nbsp;
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p137">
            ● 接客に関しての注意点&nbsp;
            <br />
            ・トイレ、リストマンから呼ばれている際、&nbsp; 卓内でキャストが誰もついていないことが起きないよう、 &nbsp;
            周りを見て動きましょう。&nbsp; また抜ける際に頂いたドリンクは飲み干すよう心がけましょう。 &nbsp;
            <br />
            ・お酒が飲めないキャストさんは「アレルギーで医者から止められている。」 &nbsp;
            等、説明してうまく切り抜けましょう。&nbsp;
            <br />
            ・知り合いのお客様が見えた場合でも挨拶や席に勝手につくなど、 &nbsp;
            リストマンの指示がない事は絶対にやめてください。&nbsp; お客様より苦情が入ります。
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="p174">
            33、応援金制度については40日以上勤務した方が対象となります。
            <br /> &nbsp;※40日以上勤務した後に申請可能 &nbsp;
            ※1人につき1回のみ申請可能です。したがって、複数の条件を満たしている場合でも、複数回の申請はできません。 &nbsp;
            ※「ダイビングアドバンス」と「ジェット免許」どちらも希望する場合は &nbsp;
            どちらか一方のみを応援金の対象として選ぶ必要があります。 応援金制度でサポートされるのは1つの資格のみとなります。&nbsp;
          </Form.Label>
        </Col>
      </Form.Row>

      {/* <Form.Row>
        <Col md={{ offset: 8, span: 4 }}>
          <Form.Label id="label_address" htmlFor="address">
            住所
          </Form.Label>
          <Form.Group>
            <Form.Control id="address" required defaultValue={props["address#address"]} type="text" name="address" />
          </Form.Group>
        </Col>
      </Form.Row> */}

      {/*REMOVEDS SIGNATURE AND DATE
      <Form.Row>
        <Col md={4}>
          <Form.Group>
            <Datetime
              initialValue={props["date#date"]}
              inputProps={{ readOnly: "readonly", name: "date", id: "date" }}
              timeFormat={false}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Signature {...props} />
*/}

      {/*CLICK INVISIBLE BUTTON TO GENERATE PDF DATA
      <div
        style={{ width: 500 + "px", height: 100 + "px", backgroundColor: +"red" }}
        onClick={() => {
          document.getElementById("press").innerHTML = JSON.stringify(
            toTemplate(document.getElementById("pageContent"), [595.28, 841.89])
          );
        }}
      ></div>
      <div>
        <pre id="press"></pre>
      </div>
    */}

      <Signature {...props} />
    </React.Fragment>
  );
}

const component = {};
component[label] = Cast_06;

component[label + "PageName"] = "入店誓約書";
component[label + "Template"] = {
  data: {
    interviewed: { page: 0, normalized: { x: 0, y: 0.09206170833337952 }, wraps: false, x: 40, y: 801.8 },
    h2_title: { page: 0, normalized: { x: 0, y: 4.663401708333379 }, wraps: false, x: 40, y: 797.23 },
    subtitle1: { page: 0, normalized: { x: 5.312164948453608, y: 40.62302243750005 }, wraps: false, x: 45.31, y: 761.27 },
    subjectA: {
      page: 0,
      normalized: { x: 5.312164948453608, y: 57.17825722916671 },
      wraps: false,
      x: 45.31,
      y: 744.71,
      width: 504.65567010309275,
      height: 21.737674062500002,
    },
    subtitle2: { page: 0, normalized: { x: 5.312164948453608, y: 82.67776316666672 }, wraps: false, x: 45.31, y: 719.21 },
    subjectB: {
      page: 0,
      normalized: { x: 5.312164948453608, y: 99.23299795833337 },
      wraps: false,
      x: 45.31,
      y: 702.66,
      width: 504.65567010309275,
      height: 21.737674062500002,
    },
    p15: { page: 0, normalized: { x: 5.312164948453608, y: 124.73250389583337 }, wraps: true, x: 45.31, y: 677.16, lines: 2 },
    p93: { page: 0, normalized: { x: 492.9689072164948, y: 153.47797868750004 }, wraps: false, x: 532.97, y: 648.41 },
    p2: { page: 0, normalized: { x: 5.312164948453608, y: 166.17614535416672 }, wraps: true, x: 45.31, y: 635.71, lines: 2 },
    p3: { page: 0, normalized: { x: 5.312164948453608, y: 190.5566253541667 }, wraps: true, x: 45.31, y: 611.33, lines: 2 },
    p32: { page: 0, normalized: { x: 5.312164948453608, y: 214.93710535416673 }, wraps: true, x: 45.31, y: 586.95, lines: 2 },
    p33: { page: 0, normalized: { x: 5.312164948453608, y: 239.3175853541667 }, wraps: true, x: 45.31, y: 562.57, lines: 2 },
    p34: { page: 0, normalized: { x: 5.312164948453608, y: 263.6980653541667 }, wraps: true, x: 45.31, y: 538.19, lines: 2 },
    p4: { page: 0, normalized: { x: 5.312164948453608, y: 288.0785453541667 }, wraps: false, x: 45.31, y: 513.81 },
    p5: { page: 0, normalized: { x: 5.312164948453608, y: 300.2687853541667 }, wraps: true, x: 45.31, y: 501.62, lines: 2 },
    p6: { page: 0, normalized: { x: 5.312164948453608, y: 324.6492653541667 }, wraps: false, x: 45.31, y: 477.24 },
    p7: { page: 0, normalized: { x: 5.312164948453608, y: 336.8395053541667 }, wraps: false, x: 45.31, y: 465.05 },
    p72: { page: 0, normalized: { x: 5.312164948453608, y: 349.0297453541667 }, wraps: false, x: 45.31, y: 452.86 },
    p73: { page: 0, normalized: { x: 5.312164948453608, y: 361.2199853541667 }, wraps: false, x: 45.31, y: 440.67 },
    p8: { page: 0, normalized: { x: 5.312164948453608, y: 373.4102253541667 }, wraps: false, x: 45.31, y: 428.48 },
    p9: { page: 0, normalized: { x: 475.9699793814433, y: 389.96546014583333 }, wraps: false, x: 515.97, y: 411.92 },
    p92: { page: 0, normalized: { x: 5.312164948453608, y: 402.66362681250007 }, wraps: true, x: 45.31, y: 399.23, lines: 7 },
    h2_title2: { page: 0, normalized: { x: 0, y: 492.3603016041667 }, wraps: false, x: 40, y: 309.53 },
    p94: { page: 0, normalized: { x: 5.312164948453608, y: 528.3199223333334 }, wraps: false, x: 45.31, y: 273.57 },
    p96: { page: 0, normalized: { x: 5.312164948453608, y: 540.5101623333334 }, wraps: true, x: 45.31, y: 261.38, lines: 7 },
    p99: { page: 0, normalized: { x: 5.312164948453608, y: 625.8418423333334 }, wraps: true, x: 45.31, y: 176.05, lines: 4 },
    p100: { page: 0, normalized: { x: 5.312164948453608, y: 674.6028023333333 }, wraps: false, x: 45.31, y: 127.29 },
    p101: { page: 0, normalized: { x: 5.312164948453608, y: 686.7930423333333 }, wraps: true, x: 45.31, y: 115.1, lines: 3 },
    p102: { page: 0, normalized: { x: 5.312164948453608, y: 723.3637623333333 }, wraps: false, x: 45.31, y: 78.53 },
    p103: { page: 0, normalized: { x: 5.312164948453608, y: 735.5540023333333 }, wraps: true, x: 45.31, y: 66.34, lines: 4 },
    p104: { page: 1, normalized: { x: 5.312164948453608, y: 22.42496233333338 }, wraps: true, x: 45.31, y: 779.47, lines: 2 },
    p105: { page: 1, normalized: { x: 5.312164948453608, y: 46.80544233333338 }, wraps: true, x: 45.31, y: 755.08, lines: 2 },
    p106: { page: 1, normalized: { x: 5.312164948453608, y: 71.18592233333338 }, wraps: true, x: 45.31, y: 730.7, lines: 5 },
    p107: { page: 1, normalized: { x: 5.312164948453608, y: 132.13712233333337 }, wraps: true, x: 45.31, y: 669.75, lines: 2 },
    p108: { page: 1, normalized: { x: 5.312164948453608, y: 156.51760233333337 }, wraps: true, x: 45.31, y: 645.37, lines: 2 },
    p109: { page: 1, normalized: { x: 5.312164948453608, y: 180.89808233333338 }, wraps: false, x: 45.31, y: 620.99 },
    p110: { page: 1, normalized: { x: 5.312164948453608, y: 193.08832233333337 }, wraps: true, x: 45.31, y: 608.8, lines: 6 },
    p111: { page: 1, normalized: { x: 5.312164948453608, y: 266.2297623333334 }, wraps: false, x: 45.31, y: 535.66 },
    p112: { page: 1, normalized: { x: 5.312164948453608, y: 278.42000233333334 }, wraps: true, x: 45.31, y: 523.47, lines: 2 },
    p113: { page: 1, normalized: { x: 5.312164948453608, y: 302.8004823333334 }, wraps: true, x: 45.31, y: 499.09, lines: 2 },
    p114: { page: 1, normalized: { x: 5.312164948453608, y: 327.18096233333335 }, wraps: true, x: 45.31, y: 474.71, lines: 3 },
    p115: { page: 1, normalized: { x: 5.312164948453608, y: 363.75168233333335 }, wraps: true, x: 45.31, y: 438.14, lines: 5 },
    p116: { page: 1, normalized: { x: 5.312164948453608, y: 424.70288233333343 }, wraps: true, x: 45.31, y: 377.19, lines: 3 },
    p117: { page: 1, normalized: { x: 5.312164948453608, y: 461.2736023333334 }, wraps: true, x: 45.31, y: 340.62, lines: 3 },
    p118: { page: 1, normalized: { x: 5.312164948453608, y: 497.84432233333337 }, wraps: false, x: 45.31, y: 304.05 },
    p119: { page: 1, normalized: { x: 5.312164948453608, y: 510.0345623333334 }, wraps: true, x: 45.31, y: 291.86, lines: 5 },
    p120: { page: 1, normalized: { x: 5.312164948453608, y: 570.9857623333334 }, wraps: true, x: 45.31, y: 230.9, lines: 4 },
    p121: { page: 1, normalized: { x: 5.312164948453608, y: 619.7467223333333 }, wraps: false, x: 45.31, y: 182.14 },
    p122: { page: 1, normalized: { x: 5.312164948453608, y: 631.9369623333333 }, wraps: true, x: 45.31, y: 169.95, lines: 4 },
    p123: { page: 1, normalized: { x: 5.312164948453608, y: 680.6979223333334 }, wraps: false, x: 45.31, y: 121.19 },
    p124: { page: 1, normalized: { x: 5.312164948453608, y: 692.8881623333334 }, wraps: false, x: 45.31, y: 109 },
    p125: { page: 1, normalized: { x: 5.312164948453608, y: 705.0784023333334 }, wraps: false, x: 45.31, y: 96.81 },
    p126: { page: 1, normalized: { x: 5.312164948453608, y: 717.2686423333333 }, wraps: true, x: 45.31, y: 84.62, lines: 2 },
    p127: { page: 1, normalized: { x: 5.312164948453608, y: 741.6491223333334 }, wraps: true, x: 45.31, y: 60.24, lines: 2 },
    p128: { page: 2, normalized: { x: 5.312164948453608, y: 4.13960233333338 }, wraps: true, x: 45.31, y: 797.75, lines: 2 },
    p129: { page: 2, normalized: { x: 5.312164948453608, y: 28.52008233333338 }, wraps: false, x: 45.31, y: 773.37 },
    p130: { page: 2, normalized: { x: 5.312164948453608, y: 40.71032233333338 }, wraps: true, x: 45.31, y: 761.18, lines: 4 },
    p131: { page: 2, normalized: { x: 5.312164948453608, y: 89.47128233333338 }, wraps: false, x: 45.31, y: 712.42 },
    p132: { page: 2, normalized: { x: 5.312164948453608, y: 101.66152233333338 }, wraps: true, x: 45.31, y: 700.23, lines: 6 },
    p133: { page: 2, normalized: { x: 5.312164948453608, y: 174.8029623333334 }, wraps: true, x: 45.31, y: 627.09, lines: 2 },
    p134: { page: 2, normalized: { x: 5.312164948453608, y: 199.18344233333337 }, wraps: true, x: 45.31, y: 602.71, lines: 3 },
    p135: { page: 2, normalized: { x: 5.312164948453608, y: 235.75416233333337 }, wraps: true, x: 45.31, y: 566.14, lines: 2 },
    p136: { page: 2, normalized: { x: 5.312164948453608, y: 260.1346423333334 }, wraps: true, x: 45.31, y: 541.76, lines: 4 },
    p137: { page: 2, normalized: { x: 5.312164948453608, y: 308.89560233333333 }, wraps: true, x: 45.31, y: 492.99, lines: 10 },
    p174: { page: 2, normalized: { x: 5.312164948453608, y: 430.79800233333333 }, wraps: true, x: 45.31, y: 371.09, lines: 8 },
    label_signature: { page: 2, normalized: { x: 441.97212371134015, y: 591.6044104583331 }, wraps: false, x: 481.97, y: 210.29 },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: { type: "RGB", red: 1, green: 0, blue: 0 },
    pixelBreakPage: 1500,
  },
};

export default component;
